body {
  margin: 0;
  padding: 0;
}

body,
#root {
  height: 100%;
}

.ant-layout-content {
  min-height: auto;
}