@import "~antd/dist/antd.css";

@font-face {
  font-family: Sommet;
  src: url("./fonts/Sommet.otf") format("opentype");
}

.landing-page {
  color: floralwhite;
  font-family: "Sommet";
}

.footer {
  background: #001529;
  color: floralwhite;
  flex-wrap: wrap;
}
.footer a {
  color: floralwhite;
}
.footer ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.footer ul li {
  padding: 0 20px;
  color: rgba(238, 238, 238, 0.7);
}
.footer ul li:first-child {
  padding-left: 0;
}
.footer ul li:last-child {
  flex: 1;
  text-align: right;
  font-size: 10px;
  font-weight: 300;
}
